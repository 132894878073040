import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import goodtolog from "../../Assests/Goodtogo.svg";
import set from "../../Assests/Set.svg";
import tick from "../../Assests/Tickregister.svg";
import { Pricing } from "../Pricing";
import HowtouseWrap from "./style";

export const HowtoUse = ({setScrollItem}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const howToUseRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log("entry---", entry)
          if (entry.isIntersecting) {

            setFadeIn(true);
            setScrollItem("howtouse")
          }
          else{
            // setScrollItem("")
          }
        });
      },
      {
        threshold: 0.5, 
      }
    );

    if (howToUseRef.current) {
      observer.observe(howToUseRef.current);
    }

    return () => {
      if (howToUseRef.current) {
        observer.unobserve(howToUseRef.current);
      }
    };
  }, []);

  return (
    <>
      <HowtouseWrap>
        <Container fluid style={{ background: "#F4F4F4" }} className="contain-margin">
          <section id="howtouse" ref={howToUseRef}>
            <div className="steps-contain">
              <Row>
                <Col>
                  <h3 className="color3 pb-5 theme-heading text-center">
                    Get Started in Few Steps
                  </h3>
                </Col>
              </Row>
              <Fade top when={fadeIn} duration={1000} distance="50px">
                <Row className="my-4">
                  <Col xl={4} lg={4} xs={12} className="text-start color2">
                    <div className="d-flex py-4">
                      <div>
                        <img src={tick} alt="icon"/>
                      </div>
                      <div className="px-4">
                        <h4 className="color1 sub-head">Register</h4>
                      </div>
                    </div>
                   
                    <ul className="px-4 text-list">
                      <li>Register with TymeplusHR to create your account.</li>
                      <li>Fill in your organization's details</li>
                      <li>
                        {" "}
                        Create a secure login credentials to access the TymeplusHR
                        platform.
                      </li>
                    </ul>
                  </Col>
                  <Col lg={4} xs={12} className="text-start color2">
                    <div className="d-flex py-4">
                      <div>
                        <img src={set} alt="icon"/>
                      </div>
                      <div className="px-4">
                        <h4 className="color1 sub-head">Set</h4>
                      </div>
                    </div>
                   
                    <ul className="px-4 text-list">
                      <li>
                        Customize your organization's settings according to your
                        preferences
                      </li>
                      <li>
                        Add employees to your organization and generate them
                        unique ID and Password
                      </li>
                    </ul>
                  </Col>
                  <Col lg={4} xs={12} className="text-start color2 px-4">
                 
                    <div className="d-flex py-4">
                      <div>
                        <img src={goodtolog} alt="icon"/>
                      </div>
                      <div className="px-4">
                        <h4 className="color1 sub-head">Good to Go</h4>
                      </div>
                    </div>
                    <p className="text-list">
                      As your employees begin using the platform, you'll have
                      the ability to track their progress, monitor their
                      activities, and collaborate more effectively across the
                      organization.
                    </p>
                  </Col>
                </Row>
                </Fade>
            </div>
          </section>
        </Container>
      </HowtouseWrap>
      {/* <Fade bottom when={fadeIn} duration={1000}>
        <Pricing setScrollItem={setScrollItem}/>
      </Fade> */}
      <Container>
        {/* <Tymeplusmobile /> */}
      </Container>
    </>
  );
};
